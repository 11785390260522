import Promise from 'es6-promise';
import Deferred from 'es6-deferred';
import LazyLoad from "./lib/lazyload";

if (typeof window.Promise === 'undefined'){
	window.Promise = Promise;
}
if (typeof window.Deferred === 'undefined'){
	window.Deferred = Deferred;
}

window.lazyLoad = LazyLoad;

window.librariesDeferred = {
	jquery: new Deferred(),
	bootstrap: new Deferred()
};

import ('jquery').then((jqueryComponent) => {
	window.$ = window.jQuery = jqueryComponent.default;
	librariesDeferred.jquery.resolve();
	window.submitContact = (e) => {
		e.preventDefault();
		$.ajax({
			type: "POST",
			url: '/messages/add',
			data: $('#frmContact').serialize(),
			dataType: 'json'
		}).done((response) => {
			if (response.result === 'fail'){
				document.querySelector('.error-contact-messages').classList.remove('d-none');
				document.querySelector('.success-contact-messages').classList.add('d-none');
			}
			else {
				document.querySelector('.error-contact-messages').classList.add('d-none');
				document.querySelector('.success-contact-messages').classList.remove('d-none');
			}
		}).fail(() => {
			document.querySelector('.error-contact-messages').classList.add('d-none');
			document.querySelector('.success-contact-messages').classList.remove('d-none');
		});
	};
	$('#frmContact').on('submit',(e) => {
		window.submitContact(e);
	});
	import('popper.js').then((Popper) => {
		window.Popper = Popper.default;
		import('bootstrap/dist/js/bootstrap.min').then(() => {
			librariesDeferred.bootstrap.resolve();
		});
	});
});
import ('Lib/font.awesome.loader');

lazyLoad.loadImages('img[data-src], img[data-srcset]', true);

if (typeof window.pageScripts !== 'undefined'){
	lazyLoad.loadScripts(window.pageScripts);
}

