import observe from "./observe";
import Deferred from 'es6-deferred';

const isWebpSupported = (feature = 'lossless') => {
	let result = new Deferred();
	if (typeof window.webpSupport === 'undefined'){
		let testImages = {
			lossy: "UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA",
			lossless: "UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==",
			alpha: "UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==",
			animation: "UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA"
		};
		let img = new Image();
		img.onload = function () {
			window.webpSupport = (img.width > 0) && (img.height > 0);
			result.resolve(window.webpSupport);
		};
		img.onerror = function () {
			window.webpSupport = false;
			result.resolve(window.webpSupport);
		};
		img.src = "data:image/webp;base64," + testImages[feature];
	}
	else {
		result.resolve(window.webpSupport);
	}
	return result;
};
const replaceExtensionToWebp = (src) => {
	return src.replace(/\.(jpe?g|png)$/i,'.webp');
};
const loadImage = (image) => {
	let transformToWebp = false;
	if (typeof window.webpSupport !== 'undefined'){
		transformToWebp = window.webpSupport;
	}
	image.onload = () => {
		image.style.opacity = 1;
		image.style.filter = 'alpha(opacity=100)';
	};
	// Add srcset
	const srcset = image.getAttribute('data-srcset');
	if (srcset) {
		image.srcset = (transformToWebp)?srcset + '.webp':srcset;
	}
	// Add src
	const src = image.getAttribute('data-src');
	if (src) {
		image.src = (transformToWebp)?src + '.webp':src;
	}
};

// Load a script from given `url`
const loadScript = (url) => {
	return new Promise((resolve, reject) => {
		const script = document.createElement('script');
		script.src = url;

		script.addEventListener('load', () => {
			// The script is loaded completely
			resolve(true);
		});

		document.head.appendChild(script);
	});
};

// Perform all promises in the order
const waterfall = (promises) => {
	return promises.reduce(
		function(p, c) {
			// Waiting for `p` completed
			return p.then(function() {
				// and then `c`
				return c().then(function(result) {
					return true;
				});
			});
		},
		// The initial value passed to the reduce method
		Promise.resolve([])
	);
};

// Load an array of scripts in order
const loadScriptsInOrder = (arrayOfJs) => {
	const promises = arrayOfJs.map(function(url) {
		return loadScript(url);
	});
	return waterfall(promises);
};

const loadCss = (file) => {
	const link = document.createElement('link');
	link.setAttribute('rel', 'stylesheet');
	link.setAttribute('href', '/path/to/js/file.css');

	document.head.appendChild(link);
};

export default class LazyLoad {
	static monitorViewPortEvent(selector, callback, settings = {}, isRepeatable = false){
		let elementsToObserve = document.querySelectorAll(selector);
		observe(elementsToObserve, callback, settings, isRepeatable);
	}
	static loadImages (selector,transformToWebp) {
		let imagesToLoad = () => {
			const imageNodes = document.querySelectorAll(selector);

			if (imageNodes.length) {
				observe(imageNodes, loadImage);
			}
		};
		if (transformToWebp){
			isWebpSupported().then((isSupported) => {
				imagesToLoad();
			});
		}
		else {
			imagesToLoad();
		}
	}

	static loadScripts(scriptsToLoad, isInOrder = false){
		if (scriptsToLoad.length > 0){
			if (isInOrder){
				return loadScriptsInOrder(scriptsToLoad);
			}
			else {
				scriptsToLoad.forEach((script) => {
					loadScript(script);
				});
			}
		}
	}

	static loadCssFiles(filesToLoad){
		if (filesToLoad.length > 0){
			filesToLoad.forEach((css) => {
				loadCss(css);
			});
		}
	}
}