
const observeWithIntersectionObserver = (elements, callback, settings = {}, isRepeatable = false) => {
	const options = {
		rootMargin: '0px',
		threshold: 0.1,
		...settings
	};

	let elementObserver = new IntersectionObserver((entries, observer) => {
		entries.forEach((entry) => {
			if (entry.intersectionRatio > 0) {
				if (!isRepeatable) {
					observer.unobserve(entry.target);
				}
				callback(entry.target);
			}
		});
	}, options);

	elements.forEach((item) => {
		elementObserver.observe(item);
	});
};

const observeWithViewPort = (elements, callback, settings = {}, isRepeatable = false) => {
	const options = {
		threshold: 0.1,
		...settings
	};

	let isElementInViewport = (el) => {
		let position = el.getBoundingClientRect();

		if (options.threshold === 1) {
			//fully visible
			return (position.top >= 0 && position.bottom <= window.innerHeight);
		}
		//partial visible
		return (position.top < window.innerHeight && position.bottom >= 0);
	};

	window.addEventListener('scroll', function() {
		if (elements.length > 0){
			elements.forEach((item) => {
				if (isElementInViewport(item)){
					callback(item);
					if (!isRepeatable){

					}
				}
			});
		}
	});
};

const observe = (elements, callback, settings = {}, isRepeatable = false) => {
	if (elements.length && typeof callback === 'function') {
		if ('IntersectionObserver' in window) {
			observeWithIntersectionObserver(elements, callback, settings, isRepeatable);
		}
		else {
			observeWithViewPort(elements, callback, settings, isRepeatable);
		}
	}
};

export default observe;
